import LayoutConfig from 'app/layout/LayoutConfig';

const settingsConfig = {
  layout: {
    style: 'layout1',
    config: LayoutConfig.defaults,
  },
  customScrollbars: true,
  theme: {
    main: 'hfc',
    navbar: 'hfc',
    toolbar: 'hfc',
    footer: 'hfc',
  },
};

export default settingsConfig;
