import { Reducer } from 'redux';
import { MyAction } from 'app/store';
import { Comment } from 'app/facades/CommentFacade';
import { SET_COMMENT, SET_COMMENTS, SET_COMMENTS_LOADING } from '../actions/comments.actions';
import _ from '@lodash';

type CommentsState = {
  map: { [key: string]: Comment };
  loading: boolean;
};

const initialState: CommentsState = {
  map: {},
  loading: true,
};

const comments: Reducer<CommentsState, MyAction> = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENTS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_COMMENT: {
      const comment = action.payload as Comment;
      state.map[comment.id] = _.merge({}, comment);

      return {
        ...state,
        map: _.merge({}, state.map),
      };
    }
    case SET_COMMENTS: {
      const list = (action.payload || []) as Comment[];
      const commentsMap: { [key: string]: Comment } = {};
      list.forEach(comment => {
        comment.editing = false;
        commentsMap[comment.id] = _.merge({}, comment);
      });
      return {
        ...state,
        map: _.merge({}, commentsMap),
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default comments;
