import React from 'react';
import { authRoles } from 'app/auth';
import { RouteConfig } from 'app/configs';

const CourseConfig: RouteConfig = {
  auth: authRoles.user,
  routes: [
    {
      path: '/courses/:courseSlug',
      component: React.lazy(() => import('./Course')),
    },
  ],
};

export default CourseConfig;
