import React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteConfig } from 'app/configs';
import { authRoles } from 'app/auth';

const LoginConfig: RouteConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/login',
      component: () => <Redirect to="/entrar" />,
      exact: true,
    },
    {
      path: '/entrar',
      component: React.lazy(() => import('./Login')),
      exact: true,
    },
  ],
};

export default LoginConfig;
