import { docData } from '../../@fuse/utils/rxfire';
import { switchMap } from 'rxjs/operators';
import { auth, firestore } from 'app/services';
import { authState } from 'rxfire/auth';
import { of } from 'rxjs';
import store from 'app/store';
import { setOpenProfileDialog, setLoadingProfileDialog, showMessage } from 'app/store/actions';
import { storage } from 'app/services';
import * as uuid from 'uuid';
import * as mime from 'mime/lite';
import { Picture } from './PictureFacade';
import environment from 'environment';

export interface UserClaims {
  [key: string]: any;
  packages: string[];
  accessLevel: string;
}

export interface User {
  id: string;

  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  language: 'pt-BR' | 'pt-PT' | 'es' | 'en-US';

  accessLevel: string;

  avatarURL: string;
  tempAvatarURL: string;
  avatar: Picture;
}

export interface ApiResponse {
  success: boolean;
  error_code: string;
  error_message?: string;
}

export interface SignupResponse {
  success: boolean;
  data: { customToken: string };
  error_code: string;
  error_message?: string;
}

export class AuthError extends Error {
  constructor(
    public message: string,
    public fields?: Record<string, string | undefined>,
    public code?: string,
  ) {
    super(message);
    this.name = 'AuthError';
  }
}

const emailErrorCodesMap = {
  'auth/email-already-in-use': 'Ya existe una cuenta con esa dirección de correo electrónico.',
  'auth/invalid-email': 'Email inválido.',
  'auth/user-not-found':
    'Correo electrónico incorrecto, probablemente ingresaste un correo electrónico incorrecto. Verifique e ingrese el correo electrónico correctamente.',
  'auth/user-disabled': 'Usuario inhabilitado.',
};

const signupEmailErrorCodesMap = {
  'auth/user-not-found':
    'Correo electrónico incorrecto, probablemente ingresaste un correo electrónico incorrecto. Verifique e ingrese el mismo correo electrónico que el de la compra.',
};

const passwordErrorCodesMap = {
  'auth/weak-password': 'Contraseña debil.',
  'auth/wrong-password': 'Contraseña incorrecta.',
  'auth/invalid-password': 'La contraseña debe tener al menos 6 caracteres.',
};
const otherErrorCodesMap = {
  'auth/network-request-failed':
    'Hubo una falla en la red, verifique su conexión a Internet y vuelva a intentarlo.',
  'auth/invalid-api-key': 'Ha ocurrido una falla interna.',
};

const REGISTER_API = 'https://us-central1-marcelo-da-luz.cloudfunctions.net/register';

class AuthFacade {
  private collection = firestore.collection('users');

  public ref(id: string) {
    return this.collection.doc(id);
  }

  public getUser$() {
    return authState(auth).pipe(
      switchMap(user => (user ? docData<User>(this.ref(user.uid), 'id') : of(null))),
    );
  }

  public async updateUser(
    userId: string,
    patchUser: (value: Partial<User>) => void,
    { firstName, lastName, phoneNumber }: Partial<User>,
    file: File | null,
  ) {
    const name = `${firstName} ${lastName}`;

    store.dispatch(setLoadingProfileDialog(true));

    if (file) {
      this.updateUserAvatar(patchUser, userId, file);
    }

    this.ref(userId)
      .set({ firstName, lastName, phoneNumber, name }, { merge: true })
      .then(() => {
        store.dispatch(showMessage({ message: 'Perfil editado!', variant: 'success' }));
        store.dispatch(setOpenProfileDialog(false));
      })
      .catch(() => {
        store.dispatch(
          showMessage({
            message: 'Lo sentimos, se ha producido un error.',
            variant: 'error',
            autoHideDuration: 0,
          }),
        );
      })
      .finally(() => {
        store.dispatch(setLoadingProfileDialog(false));
      });
  }

  public async updateUserAvatar(patchUser: (value: Partial<User>) => void, userId: string, file: File) {
    const id = uuid.v4();

    const contentType = mime.getType(file.name)!;
    const extension = mime.getExtension(contentType);

    if (!contentType || !extension) {
      throw new Error('¡Tipo de archivo no reconocido!');
    }

    const task = storage
      .ref('temp/avatars')
      .child(`${id}.${extension}`)
      .put(file, { cacheControl: 'public, max-age=31536000', contentType });

    const snapshot = await task;

    const tempAvatarURL = (await snapshot.ref.getDownloadURL()) || '';

    this.ref(userId)
      .set({ tempAvatarURL }, { merge: true })
      .then(() => {
        patchUser({ avatarURL: 'assets/imgs/loading.gif' });
      });
  }

  public async submitLogin(fields: Record<'email' | 'password', string>) {
    try {
      await auth.signInWithEmailAndPassword(fields.email.trim(), fields.password);

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        error: {
          email: emailErrorCodesMap[error.code as keyof typeof emailErrorCodesMap],
          password: passwordErrorCodesMap[error.code as keyof typeof passwordErrorCodesMap],
          other: error.code === 'auth/invalid-api-key' && error.message,
        },
      };
    }
  }

  public async sendPasswordResetEmail(email: string) {
    try {
      auth.languageCode = environment.lang;
      await auth.sendPasswordResetEmail(email.trim());

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        error: {
          email: emailErrorCodesMap[error.code as keyof typeof emailErrorCodesMap],
          other: error.code === 'auth/invalid-api-key' && error.message,
        },
      };
    }
  }

  public async loginWithCustomToken(customToken: string) {
    try {
      await auth.signInWithCustomToken(customToken);
    } catch (error: any) {
      throw new AuthError(otherErrorCodesMap[error.code as keyof typeof otherErrorCodesMap]);
    }
  }

  public async loginWithEmailAndPassword(email: string, password: string) {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error: any) {
      throw new AuthError(otherErrorCodesMap[error.code as keyof typeof otherErrorCodesMap], {
        email: emailErrorCodesMap[error.code as keyof typeof emailErrorCodesMap],
        password: passwordErrorCodesMap[error.code as keyof typeof passwordErrorCodesMap],
      });
    }
  }

  public async fetchSignup(fields: Record<'email' | 'password', string>): Promise<SignupResponse> {
    const res = await fetch(REGISTER_API, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields),
    });

    if (!res.ok && res.status !== 400) {
      throw new AuthError('Lo siento, parece que hay problemas en Internet.');
    }

    return res.json();
  }

  public async submitSignup(fields: Record<'email' | 'password', string>): Promise<{
    customToken: string;
  }> {
    const data = await this.fetchSignup(fields);

    if (!data.success) {
      if (!data.error_code) {
        throw new AuthError(`Lo siento, ha ocurrido un error, ${data.error_message}.`);
      }

      const emailMap = { ...emailErrorCodesMap, ...signupEmailErrorCodesMap };

      throw new AuthError(
        otherErrorCodesMap[data.error_code as keyof typeof otherErrorCodesMap],
        {
          email: emailMap[data.error_code as keyof typeof emailMap],
          password: passwordErrorCodesMap[data.error_code as keyof typeof passwordErrorCodesMap],
        },
        data.error_code,
      );
    }

    return { customToken: data.data.customToken };
  }

  public async signupAndLogin(fields: Record<'email' | 'password', string>) {
    const response = await this.submitSignup(fields);

    await this.loginWithCustomToken(response.customToken);

    return response;
  }
}

const instance = new AuthFacade();

export default instance;
