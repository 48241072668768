import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import _ from '@lodash';
import { Theme } from '@material-ui/core';
import { useTypedSelector } from 'app/store';
import Layout from 'app/layout/Layout';
import { useRouteState } from 'app/contexts/RouteContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& h1': {
      ...theme.typography.h1,
    },
    '& h2': {
      ...theme.typography.h2,
    },
    '& h3': {
      ...theme.typography.h3,
    },
    '& h4': {
      ...theme.typography.h4,
    },
    '& h5': {
      ...theme.typography.h5,
    },
    '& h6': {
      ...theme.typography.h6,
    },
    '& p': {
      ...theme.typography.body1,
    },
    '& .text-primary': {
      color: theme.palette.primary.main,
    },
    '& .text-secondary': {
      color: theme.palette.secondary.main,
    },
    '& .text-success': {
      color: theme.palette.success.main,
    },
    '& .text-warning': {
      color: theme.palette.warning.main,
    },
    '& .text-error': {
      color: theme.palette.error.main,
    },
    '& .text-info': {
      color: theme.palette.info.main,
    },
    // '& code:not([class*="language-"])': {
    //     color          : theme.palette.secondary.dark,
    //     backgroundColor: '#F5F5F5',
    //     padding        : '2px 3px',
    //     borderRadius   : 2,
    //     lineHeight     : 1.7
    // },
    // '& table.simple tbody tr td'      : {
    //     borderColor: theme.palette.divider
    // },
    // '& table.simple thead tr th'      : {
    //     borderColor: theme.palette.divider
    // },
    // '& [class^="border-"]'            : {
    //     borderColor: theme.palette.divider
    // },
    // '& [class*="border-"]'            : {
    //     borderColor: theme.palette.divider
    // }
  },
}));

function FuseLayout(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const defaultSettings = useTypedSelector(({ fuse }) => fuse.settings.defaults);
  const settings = useTypedSelector(({ fuse }) => fuse.settings.current);

  const classes = useStyles();
  const routes = useRouteState();

  React.useEffect(() => {
    function routeSettingsCheck() {
      const matched = matchRoutes(routes, props.location.pathname)[0];

      if (matched && matched.route.settings) {
        const routeSettings = _.merge({}, defaultSettings, matched.route.settings);
        if (!_.isEqual(settings, routeSettings)) {
          dispatch(Actions.setSettings(_.merge({}, routeSettings)));
        }
      } else {
        if (!_.isEqual(settings, defaultSettings)) {
          dispatch(Actions.resetSettings());
        }
      }
    }

    routeSettingsCheck();
  }, [defaultSettings, dispatch, props.location.pathname, routes, settings]);

  return <Layout classes={{ root: classes.root }} />;
}

export default withRouter(React.memo(FuseLayout));
