import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { useTypedSelector } from 'app/store';

function FuseTheme(props: any) {
  const mainTheme = useTypedSelector(({ fuse }) => fuse.settings.mainTheme);

  return <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>;
}

export default React.memo(FuseTheme);
