import React from 'react';
import PropTypes from 'prop-types';
import {
  AnimationSetup,
  VelocityTransitionGroup,
  VelocityTransitionGroupProps,
} from 'velocity-react';
import 'velocity-animate/velocity.ui';
import _ from '@lodash';

const enterAnimationDefaults: AnimationSetup = {
  animation: 'transition.fadeIn',
  stagger: 50,
  duration: 200,
  visibility: 'visible',
  delay: 0,
};

const leaveAnimationDefaults: AnimationSetup = {
  stagger: 50,
  duration: 200,
  visibility: 'visible',
  delay: 0,
};

const FuseAnimateGroup: React.FunctionComponent<VelocityTransitionGroupProps> = props => {
  const newProps = _.merge(
    {},
    {
      enter: enterAnimationDefaults,
      leave: leaveAnimationDefaults,
    },
    props
  );

  return <VelocityTransitionGroup {...newProps} children={props.children} />;
};

FuseAnimateGroup.propTypes = {
  children: PropTypes.any,
};

FuseAnimateGroup.defaultProps = {
  enter: enterAnimationDefaults,
  leave: leaveAnimationDefaults,
  runOnMount: true,
  enterHideStyle: {
    visibility: 'visible',
  },
  enterShowStyle: {
    visibility: 'hidden',
  },
};

export default React.memo(FuseAnimateGroup);
