import { authRoles } from 'app/auth';
import { RouteConfig } from 'app/configs';
import React from 'react';

const ProfileConfig: RouteConfig = {
  auth: authRoles.user,
  routes: [
    {
      path: '/profile',
      component: React.lazy(() => import('./Profile')),
    },
  ],
};

export default ProfileConfig;
