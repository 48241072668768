import { Comment } from 'app/facades/CommentFacade';

export const SET_COMMENTS = '[COMMENTS] SET COMMENTS';
export const SET_COMMENT = '[COMMENTS] SET COMMENT';
export const SET_COMMENTS_LOADING = '[COMMENTS] SET LOADING';

export function setComment(comment: Comment) {
  return {
    type: SET_COMMENT,
    payload: comment,
  };
}

export function setComments(comments: Comment[]) {
  return {
    type: SET_COMMENTS,
    payload: comments,
  };
}

export function setCommentsLoading(loading: boolean) {
  return {
    type: SET_COMMENTS_LOADING,
    payload: loading,
  };
}
