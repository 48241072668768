import 'fontsource-roboto/300-normal.css';
import 'fontsource-roboto/400-normal.css';
import 'fontsource-roboto/500-normal.css';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './app/App';

ReactDOM.render(<App />, document.getElementById('root'));
