import { collectionToData, snapToData } from '@fuse/utils/rxfire';
import { firestore } from 'app/services';

export interface Picture {
  id: string;

  name: string;
  main: PictureSize;
  original: PictureSize;
  project: PictureSize;
  sizes: PictureSize[];
}

export interface PictureSize {
  width: number;
  height: number;
  link: string;
  path: string;
  bucket: string;
  type: 'image/jpeg' | 'image/png';
}

class PictureFacade {
  private collection = firestore.collection('pictures');

  public async getAll() {
    return collectionToData<Picture>(await this.collection.get());
  }

  public async getOne(id: string): Promise<Picture | null> {
    return snapToData(await this.collection.doc(id).get());
  }
}

const instance = new PictureFacade();

export default instance;
