import React from 'react';
import { renderRoutes } from 'react-router-config';
import { useTypedSelector } from 'app/store';
import { makeStyles } from '@material-ui/styles';
import { Hidden, Theme } from '@material-ui/core';
import clsx from 'clsx';
import ToolbarLayout1 from './components/ToolbarLayout1';
import FooterLayout1 from './components/FooterLayout1';
import { FuseDialog, FuseMessage, FuseScrollbars } from '@fuse';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import { useRouteState } from 'app/contexts/RouteContext';
import LoadingPage from 'app/pages/loading/LoadingPage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&.boxed': {
      maxWidth: 1280,
      margin: '0 auto',
      boxShadow: theme.shadows[3],
    },
    '&.scroll-body': {
      '& $wrapper': {
        height: 'auto',
        flex: '0 0 auto',
        overflow: 'auto',
      },
      '& $contentWrapper': {},
      '& $content': {},
    },
    '&.scroll-content': {
      '& $wrapper': {},
      '& $contentWrapper': {},
      '& $content': {},
    },
    '& .navigation': {
      '& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon': {
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
    '& img[data-sizes="auto"]': {
      display: 'block',
      width: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: '1 1 auto',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 3,
    overflow: 'hidden',
    flex: '1 1 auto',
  },
  content: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 2,
  },
}));

function Layout(props: any) {
  const config = useTypedSelector(({ fuse }) => fuse.settings.current.layout.config);

  const scrollbarRef = React.useRef<HTMLDivElement>(null);

  const routes = useRouteState();

  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, config.mode, 'scroll-' + config.scroll)}>
      <div className="flex flex-1 flex-col overflow-hidden relative">
        {config.toolbar.display && config.toolbar.position === 'above' && <ToolbarLayout1 />}

        <div className={classes.wrapper}>
          <Hidden mdUp>
            {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}
          </Hidden>

          <div className={classes.contentWrapper}>
            {config.toolbar.display &&
              config.toolbar.position === 'below' &&
              config.toolbar.style === 'fixed' && <ToolbarLayout1 />}

            <FuseScrollbars
              ref={scrollbarRef}
              className={classes.content}
              scrollToTopOnChildChange={true}
            >
              {config.toolbar.display &&
                config.toolbar.position === 'below' &&
                config.toolbar.style !== 'fixed' && <ToolbarLayout1 scrollbarRef={scrollbarRef} />}

              <FuseDialog />

              <div className="flex-1">
                <div className="min-h-screen">
                  <React.Suspense fallback={<LoadingPage />}>{renderRoutes(routes)}</React.Suspense>
                </div>
              </div>

              {config.footer.display &&
                config.footer.position === 'below' &&
                config.footer.style !== 'fixed' && <FooterLayout1 />}
            </FuseScrollbars>

            {config.footer.display &&
              config.footer.position === 'below' &&
              config.footer.style === 'fixed' && <FooterLayout1 />}
          </div>
        </div>

        {config.footer.display && config.footer.position === 'above' && <FooterLayout1 />}
      </div>

      <FuseMessage />
    </div>
  );
}

export default Layout;
