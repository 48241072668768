import { Navigation } from 'app/facades/NavigationFacade';

const navigationConfig: Navigation[] = [
  {
    id: 'user-interface',
    title: '',
    type: 'group',
    icon: 'web',
    children: [
      {
        id: 'courses',
        title: 'Mis cursos',
        type: 'item',
        icon: 'school',
        url: '/courses',
      },
      {
        id: 'discover',
        title: 'descúbrelo',
        type: 'item',
        icon: 'collections_bookmark',
        url: '/discover',
      },
      {
        id: 'app',
        title: 'Acelerador de ingles Prime',
        type: 'item',
        icon: 'phone_iphone',
        url: 'https://es.marcelodaluz.app',
        target: '_blank',
      },
      {
        id: 'talk-to-us',
        title: 'Hable con nosotros',
        type: 'item',
        icon: 'info',
        url: '/talk-to-us',
      },
    ],
  },
];

export default navigationConfig;
