import React from 'react';
import { Dialog } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import * as Actions from 'app/store/actions';
import { useTypedSelector } from 'app/store';

function FuseDialog() {
  const dispatch = useDispatch();
  const state = useTypedSelector(({ fuse }) => fuse.dialog.state);
  const options = useTypedSelector(({ fuse }) => fuse.dialog.options);

  return (
    <Dialog
      open={state}
      onClose={() => dispatch(Actions.closeDialog())}
      aria-labelledby="fuse-dialog-title"
      {...options}
    />
  );
}

export default FuseDialog;
