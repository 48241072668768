import { collectionData } from '../../@fuse/utils/rxfire';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import { firestore } from 'app/services';
import store from 'app/store';
import { setComments, setCommentsLoading, showMessage } from 'app/store/actions';
import { User } from './AuthFacade';

export interface Comment {
  id: string;
  parentId: string;
  message: string;
  user: {
    id: string;
    name: string;
    avatarURL: string;
  };
  commentIn: 'video' | 'comment';

  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;

  editing: boolean;
  showList: boolean;
  showForm: boolean;
}

class CommentFacade {
  collection = firestore.collection('comments');

  getAll$(parentId: string) {
    return collectionData<Comment>(this.collection.where('parentId', '==', parentId), 'id').pipe(
      map(list =>
        list.sort((a, b) => (!a.createdAt ? -1 : !b.createdAt ? 1 : b.createdAt.seconds - a.createdAt.seconds))
      )
    );
  }

  loadComments(parentId: string) {
    store.dispatch(setCommentsLoading(true));
    return this.getAll$(parentId).subscribe(comments => {
      store.dispatch(setComments(comments));
    });
  }

  post(message: string, user: User, parentId: string, commentIn: 'comment' | 'video') {
    const comment = {
      parentId: parentId,
      message: message,
      user: {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        avatarURL: user.avatarURL || null,
      },
      commentIn: commentIn,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    } as Comment;

    this.collection
      .add(comment)
      .then(() => {
        store.dispatch(showMessage({ message: 'Comentário adicionado!', variant: 'success' }));
      })
      .catch(() => {
        store.dispatch(showMessage({ message: 'Não foi possível realizar essa operação!', variant: 'error' }));
      });
  }

  update(comment: Comment, message: string, user: User) {
    comment.message = message;
    comment.user = {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      avatarURL: user.avatarURL || '',
    };
    comment.updatedAt = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;

    this.collection
      .doc(comment.id)
      .set(comment, { merge: true })
      .then(() => {
        store.dispatch(showMessage({ message: 'Comentário editado!', variant: 'success' }));
      })
      .catch(() => {
        store.dispatch(showMessage({ message: 'Não foi possível realizar essa operação!', variant: 'error' }));
      });
  }

  remove(commentId: string) {
    this.collection
      .doc(commentId)
      .delete()
      .then(() => {
        store.dispatch(showMessage({ message: 'Comentário excluído!', variant: 'success' }));
      })
      .catch(() => {
        store.dispatch(showMessage({ message: 'Não foi possível realizar essa operação!', variant: 'error' }));
      });
  }
}

const instance = new CommentFacade();

export default instance;
