import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTypedSelector } from 'app/store';
import { ThemeProvider } from '@material-ui/styles';

const useStyles = makeStyles<Theme>(theme => ({
	header: {
		height: 'auto',
		minHeight: 120,
		display: 'flex',
		background:
			'linear-gradient(to right, ' +
			theme.palette.primary.dark +
			' 0%, ' +
			theme.palette.primary.main +
			' 100%)',
		color: theme.palette.primary.contrastText,
		backgroundSize: 'cover',
		backgroundColor: theme.palette.primary.dark,
	},
}));

type FusePageCleanClassKey = 'header' | 'content';

type FusePageCleanProps = {
	classes?: Partial<Record<FusePageCleanClassKey, string>>;
	header: JSX.Element;
	content: JSX.Element;
};

const FusePageClean: React.FunctionComponent<FusePageCleanProps> = props => {
	const mainThemeDark = useTypedSelector(({ fuse }) => fuse.settings.mainThemeDark);
	const classes = useStyles(props);

	return (
		<div className="flex flex-col w-full">
			<div className={classes.header}>
				<ThemeProvider theme={mainThemeDark}>{props.header}</ThemeProvider>
			</div>

			{props.content}
		</div>
	);
};

export default React.memo(FusePageClean);
