import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
  apiKey: 'AIzaSyA1kqWJU4z-VX5jb_oHL93KDnkMDgjNINM',
  authDomain: 'marcelo-da-luz.firebaseapp.com',
  databaseURL: 'https://marcelo-da-luz.firebaseio.com',
  projectId: 'marcelo-da-luz',
  storageBucket: 'marcelo-da-luz.appspot.com',
  messagingSenderId: '1073544587445',
  appId: '1:1073544587445:web:58b02ffff02ab36d9276fc',
};

firebase.initializeApp(config);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

firestore.enablePersistence();
