import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/FuseUtils';
import { RouteConfig } from './RouteConfig';
import LoginConfig from 'app/pages/login/LoginConfig';
import ForgotPasswordConfig from 'app/pages/forgot-password/ForgotPasswordConfig';
import RegisterConfig from 'app/pages/register/RegisterConfig';
import CoursesConfig from 'app/pages/courses/CoursesConfig';
import DiscoverConfig from 'app/pages/discover/DiscoverConfig';
import CourseConfig from 'app/pages/course/CourseConfig';
import TalkToUsConfig from 'app/pages/talk-to-us/TalkToUsConfig';
import ProfileConfig from 'app/pages/profile/ProfileConfig';

const routeConfigs: RouteConfig[] = [
  LoginConfig,
  ForgotPasswordConfig,
  RegisterConfig,
  CoursesConfig,
  CourseConfig,
  DiscoverConfig,
  TalkToUsConfig,
  ProfileConfig,
];

const routes: RouteConfig[] = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/courses" />,
  },
  {
    component: () => <h1>Not Found</h1>,
  },
];

export default routes;
